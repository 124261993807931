import { Controller } from "stimulus";
import flatpickr from 'flatpickr';
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";

export default class extends Controller {
  connect() {
    flatpickr("#receipt_gold_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      plugins: [new rangePlugin({ input: "#receipt_gold_end_date" })],
    });

    flatpickr("#receipt_gold_customer_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      plugins: [new rangePlugin({ input: "#receipt_gold_customer_end_date" })],
    });

    flatpickr("#exchange_gold_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      plugins: [new rangePlugin({ input: "#exchange_gold_end_date" })],
    });

    flatpickr("#exchange_gold_supplier_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      plugins: [new rangePlugin({ input: "#exchange_gold_supplier_end_date" })],
    });

    flatpickr("#internal_transfer_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      plugins: [new rangePlugin({ input: "#internal_transfer_end_date" })],
    });

    flatpickr("#exchange_cash_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,
      plugins: [new rangePlugin({ input: "#exchange_cash_end_date" })],
    });
    flatpickr("#exchange_cash_supplier_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,
      plugins: [new rangePlugin({ input: "#exchange_cash_supplier_end_date" })],
    });

    flatpickr("#receipt_cash_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,
      plugins: [new rangePlugin({ input: "#receipt_cash_end_date" })],
    });

    flatpickr("#receipt_cash_customer_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,
      plugins: [new rangePlugin({ input: "#receipt_cash_customer_end_date" })],
    });

    flatpickr("#sales_gold_close_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#sales_gold_close_end_date" })],
    });

    flatpickr("#purchase_gold_close_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#purchase_gold_close_end_date" })],
    });

    flatpickr("#change_kerat_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#change_kerat_end_date" })],
    });

    flatpickr("#exchange_gold_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#change_kerat_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#exchange_cash_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });
    
    flatpickr("#receipt_cash_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#account_created_at", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d"
    });

    flatpickr("#main_balance_cash_form", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });
    
    flatpickr("#internal_transfer_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#daily_form_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
    });

    flatpickr("#company_payment_form_created_at", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
    });

    flatpickr("#operation_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#operation_end_date" })],
    });

    flatpickr("#company_invoice_form_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#company_invoice_form_end_date" })],
    });
    flatpickr("#operation_item_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#operation_item_end_date" })],
    });

    flatpickr("#gemstone_report_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#gemstone_report_end_date" })],
    });

    flatpickr("#purchase_gold_close_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#purchase_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });
    flatpickr("#debit_purchase_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#main_balance_gold_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#purchase_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#purchase_end_date" })],
    });

    flatpickr("#debit_purchase_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#debit_purchase_end_date" })],
    });

    flatpickr("#main_balance_gold_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#main_balance_gold_end_date" })],
    });
    flatpickr("#main_balance_cash_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      allowInput: true,

      plugins: [new rangePlugin({ input: "#main_balance_cash_end_date" })],
    });

    flatpickr("#receipt_gold_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#sales_gold_close_date", {
      altFormat: "d-m-Y",
      dateFormat: "Y-m-d",
      defaultDate: new Date(),
    });

    flatpickr("#sales_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });

    flatpickr("#credit_sale_form_date", {
      altFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d H:i",
      enableTime: true,
    });
    flatpickr("#sale_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#sale_end_date" })],
    });
    flatpickr("#daily_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#daily_end_date" })],
    });

    flatpickr("#credit_sale_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#credit_sale_end_date" })],
    });

    flatpickr("#budget_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#budget_end_date" })],
    });
    flatpickr("#income_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#income_end_date" })],
    });

    flatpickr("#trial_balance_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#trial_balance_end_date" })],
    });

    flatpickr("#trial_balance_cash_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#trial_balance_cash_end_date" })],
    });

    flatpickr("#tax_sales_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#tax_sales_end_date" })],
    });
    flatpickr("#tax_purchases_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#tax_purchases_end_date" })],
    });

    flatpickr("#item_store_report_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#item_store_report_end_date" })],
    });

    flatpickr("#item_store_stock_start_date", {
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",

      plugins: [new rangePlugin({ input: "#item_store_stock_end_date" })],
    });

  }
}
